:root {
  --circle-width: min(250px, 100vw - 2.5rem);
  --hero-image-width: calc(var(--circle-width) * 0.8);
  --cyan-text: rgb(8, 242, 250);
}

html {
  overflow: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .circle-parent {
    animation: spin 6s infinite linear;
  }
  
  @keyframes spin {
    to {
      rotate: 360deg;
    }
  }
}

.App {
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.hero {
  width: 300px;
  aspect-ratio: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-content: center;
  align-content: center;
}

.circle-parent {
  margin: auto;
  width: var(--circle-width);
  height: var(--circle-width);
  display: flex;
  flex-direction: column;
  z-index: 1;
  animation-play-state: paused;
}

.circle-rotate{
  animation-play-state: running;
}

.top-circle-text,
.bottom-circle-text {
  width: var(--circle-width);
  height: calc(var(--circle-width)/2);
  overflow: visible;
  color: var(--cyan-text);
  user-select: none;
}


.circle-parent,
.circle-image {
  grid-column: 1;
  grid-row: 1;
  margin: auto;
}

.circle-image {
  width: var(--hero-image-width);
  aspect-ratio: 1;
  overflow: hidden;
  border-radius: 50%;
  outline: 3px solid var(--cyan-text);
}

.top-circle-text {
  font-size: 2.5rem;
  z-index: 100;
  margin-top: 0;
}

.bottom-circle-text {
  font-size: 1.5rem;
  color: rgb(244, 97, 246);
}

.selfie {
  width: 100%;
  height: 138%;
  object-fit: cover;
  object-position: bottom;
}

.progress {
  width: 100%;
  color: aquamarine;
}

.App-link {
  color: #61dafb;
}